function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
const saveEmail = async (e, email, setConfirmed, setError) => {
    //handles when the email is submitted
    try {
        e.preventDefault();

        //Validate email
        const isValidEmail = validateEmail(email);
        if(!isValidEmail) throw new Error('Email is not valid');
        setConfirmed(true)
        return false
    } catch (e) {
        //TODO: handle error validation
        setError(true);
        return false

    }

}

export default saveEmail;
